var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var h4,i4,j4,k4;h4=function(a,b,c){var d=$CLJS.V0(a,b);d=$CLJS.Qf(d);d=$CLJS.M.h(d,$CLJS.MP);d=$CLJS.vW($CLJS.Mm.j($CLJS.pi([c]),$CLJS.IG,$CLJS.Zd),d);if(!$CLJS.m(d))throw $CLJS.zj($CLJS.UH("No aggregation with uuid {0}",$CLJS.G([c])),new $CLJS.k(null,3,[$CLJS.Ut,c,$CLJS.uO,a,$CLJS.o1,b],null));return d};i4=new $CLJS.N("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);
j4=new $CLJS.N("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);k4=new $CLJS.N("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);$CLJS.Mqa=new $CLJS.N(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.z1.o(null,$CLJS.MP,function(a,b){var c=$CLJS.Rf($CLJS.MP.g($CLJS.V0(a,b)));return $CLJS.m(c)?$CLJS.c2($CLJS.VH("and"),function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.be(l,u);v=$CLJS.a1.D(a,b,v,$CLJS.b1);q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}q=$CLJS.C(g);return $CLJS.nf($CLJS.a1.D(a,
b,q,$CLJS.b1),f($CLJS.zd(g)))}return null}},null,null)}(c)}()):null});$CLJS.Z0.o(null,$CLJS.MP,function(a,b,c){$CLJS.J(c,0,null);var d=$CLJS.J(c,1,null),e=$CLJS.Qf(d);d=$CLJS.M.h(e,$CLJS.DG);e=$CLJS.M.h(e,$CLJS.lk);c=$CLJS.J(c,2,null);c=h4(a,b,c);return $CLJS.gn.l($CLJS.G([$CLJS.$0(a,b,c),new $CLJS.k(null,2,[$CLJS.g0,$CLJS.G0,$CLJS.E1,$CLJS.IG.g($CLJS.Zd(c))],null),$CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.DG,d],null):null,$CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.lk,e],null):null]))});
$CLJS.w1.o(null,$CLJS.MP,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);return $CLJS.a1.D(a,b,h4(a,b,c),d)});$CLJS.lV(j4,k4);
for(var l4=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lD,$CLJS.MJ],null)),m4=null,n4=0,o4=0;;)if(o4<n4){var Nqa=m4.$(null,o4);$CLJS.lV(Nqa,j4);o4+=1}else{var p4=$CLJS.A(l4);if(p4){var q4=p4;if($CLJS.re(q4)){var r4=$CLJS.$c(q4),Oqa=$CLJS.ad(q4),Pqa=r4,Qqa=$CLJS.E(r4);l4=Oqa;m4=Pqa;n4=Qqa}else{var Rqa=$CLJS.C(q4);$CLJS.lV(Rqa,j4);l4=$CLJS.D(q4);m4=null;n4=0}o4=0}else break}
$CLJS.w1.o(null,j4,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);if($CLJS.m(c))switch(a=$CLJS.a1.D(a,b,c,d),e=e instanceof $CLJS.N?e.S:null,e){case "count":return $CLJS.UH("Count of {0}",$CLJS.G([a]));case "cum-count":return $CLJS.UH("Cumulative count of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}else switch(e=e instanceof $CLJS.N?e.S:null,e){case "count":return $CLJS.VH("Count");case "cum-count":return $CLJS.VH("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});$CLJS.x1.o(null,j4,function(a,b,c){a=$CLJS.J(c,0,null);a=a instanceof $CLJS.N?a.S:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));}});$CLJS.Z0.o(null,j4,function(a,b,c){var d=$CLJS.U.j,e=$CLJS.tj($CLJS.Z0,k4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.U,a,$CLJS.Ll,$CLJS.SI)});$CLJS.w1.o(null,$CLJS.ZI,function(){return $CLJS.VH("Case")});
$CLJS.x1.o(null,$CLJS.ZI,function(){return"case"});$CLJS.lV(i4,k4);for(var s4=$CLJS.A(new $CLJS.S(null,9,5,$CLJS.T,[$CLJS.CJ,$CLJS.QK,$CLJS.MI,$CLJS.Zl,$CLJS.uK,$CLJS.rk,$CLJS.zK,$CLJS.sK,$CLJS.QI],null)),t4=null,u4=0,v4=0;;)if(v4<u4){var Sqa=t4.$(null,v4);$CLJS.lV(Sqa,i4);v4+=1}else{var w4=$CLJS.A(s4);if(w4){var x4=w4;if($CLJS.re(x4)){var y4=$CLJS.$c(x4),Tqa=$CLJS.ad(x4),Uqa=y4,Vqa=$CLJS.E(y4);s4=Tqa;t4=Uqa;u4=Vqa}else{var Wqa=$CLJS.C(x4);$CLJS.lV(Wqa,i4);s4=$CLJS.D(x4);t4=null;u4=0}v4=0}else break}
$CLJS.x1.o(null,i4,function(a,b,c){a=$CLJS.J(c,0,null);$CLJS.J(c,1,null);$CLJS.J(c,2,null);c=a instanceof $CLJS.N?a.S:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.g(c)].join(""));}});
$CLJS.w1.o(null,i4,function(a,b,c,d){var e=$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);a=$CLJS.a1.D(a,b,c,d);e=e instanceof $CLJS.N?e.S:null;switch(e){case "avg":return $CLJS.UH("Average of {0}",$CLJS.G([a]));case "cum-sum":return $CLJS.UH("Cumulative sum of {0}",$CLJS.G([a]));case "distinct":return $CLJS.UH("Distinct values of {0}",$CLJS.G([a]));case "max":return $CLJS.UH("Max of {0}",$CLJS.G([a]));case "median":return $CLJS.UH("Median of {0}",$CLJS.G([a]));case "min":return $CLJS.UH("Min of {0}",
$CLJS.G([a]));case "stddev":return $CLJS.UH("Standard deviation of {0}",$CLJS.G([a]));case "sum":return $CLJS.UH("Sum of {0}",$CLJS.G([a]));case "var":return $CLJS.UH("Variance of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});$CLJS.w1.o(null,$CLJS.mJ,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null);c=$CLJS.J(c,3,null);return $CLJS.UH("{0}th percentile of {1}",$CLJS.G([c,$CLJS.a1.D(a,b,e,d)]))});$CLJS.x1.o(null,$CLJS.mJ,function(){return"percentile"});
$CLJS.lV($CLJS.mJ,k4);$CLJS.w1.o(null,$CLJS.NK,function(a,b,c,d){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var e=$CLJS.J(c,2,null);$CLJS.J(c,3,null);return $CLJS.UH("Sum of {0} matching condition",$CLJS.G([$CLJS.a1.D(a,b,e,d)]))});$CLJS.x1.o(null,$CLJS.NK,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var d=$CLJS.J(c,2,null);$CLJS.J(c,3,null);return["sum_where_",$CLJS.p.g($CLJS.y1.j(a,b,d))].join("")});$CLJS.lV($CLJS.NK,k4);$CLJS.w1.o(null,$CLJS.YJ,function(){return $CLJS.VH("Share of rows matching condition")});
$CLJS.x1.o(null,$CLJS.YJ,function(){return"share"});$CLJS.lV($CLJS.YJ,k4);$CLJS.w1.o(null,$CLJS.eK,function(){return $CLJS.VH("Count of rows matching condition")});$CLJS.x1.o(null,$CLJS.eK,function(){return"count-where"});$CLJS.lV($CLJS.eK,k4);
$CLJS.Z0.o(null,k4,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);var d=$CLJS.J(c,2,null),e=$CLJS.gn.l;d=$CLJS.m(d)?$CLJS.li($CLJS.$0(a,b,d),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lR],null)):null;var f=$CLJS.tj($CLJS.Z0,$CLJS.Pj);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.gn,$CLJS.G([d,a]))});$CLJS.bW.o(null,$CLJS.MP,function(a){return a});$CLJS.w1.o(null,$CLJS.nW,function(a,b,c){a=$CLJS.Qf(c);a=$CLJS.M.h(a,$CLJS.qW);return $CLJS.TI.g(a.A?a.A():a.call(null))});
$CLJS.C1.o(null,$CLJS.nW,function(a,b,c){var d=$CLJS.Qf(c);a=$CLJS.M.h(d,$CLJS.AV);b=$CLJS.M.h(d,$CLJS.qW);c=$CLJS.M.h(d,$CLJS.oW);d=$CLJS.M.h(d,$CLJS.B0);a=$CLJS.U.l(b.A?b.A():b.call(null),$CLJS.u1,$CLJS.jG(a),$CLJS.G([$CLJS.t1,c]));return null!=d?$CLJS.U.j(a,$CLJS.i1,d):a});