var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.util.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var V2,Fpa,W2,X2,Gpa,Hpa,Y2,Z2,Ipa,Jpa,Kpa,Lpa,d3,Npa,Opa,Ppa,Qpa,g3,h3,i3,j3,l3,Spa,m3,n3,Tpa,$2,b3;V2=function(a,b){return $CLJS.oe(a)?$CLJS.M.h(a,b):a};
Fpa=function(){return function(){function a(g,l,n){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Xk.j?$CLJS.Xk.j(g,l,n):$CLJS.Xk.call(null,g,l,n),$CLJS.nn.j?$CLJS.nn.j(g,l,n):$CLJS.nn.call(null,g,l,n)],null)}function b(g,l){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Xk.h?$CLJS.Xk.h(g,l):$CLJS.Xk.call(null,g,l),$CLJS.nn.h?$CLJS.nn.h(g,l):$CLJS.nn.call(null,g,l)],null)}function c(g){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Xk.g?$CLJS.Xk.g(g):$CLJS.Xk.call(null,g),$CLJS.nn.g?$CLJS.nn.g(g):$CLJS.nn.call(null,
g)],null)}function d(){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Xk.A?$CLJS.Xk.A():$CLJS.Xk.call(null),$CLJS.nn.A?$CLJS.nn.A():$CLJS.nn.call(null)],null)}var e=null,f=function(){function g(n,q,u,v){var x=null;if(3<arguments.length){x=0;for(var y=Array(arguments.length-3);x<y.length;)y[x]=arguments[x+3],++x;x=new $CLJS.z(y,0,null)}return l.call(this,n,q,u,x)}function l(n,q,u,v){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.R.R($CLJS.Xk,n,q,u,v),$CLJS.R.R($CLJS.nn,n,q,u,v)],null)}g.v=3;g.B=function(n){var q=
$CLJS.C(n);n=$CLJS.D(n);var u=$CLJS.C(n);n=$CLJS.D(n);var v=$CLJS.C(n);n=$CLJS.zd(n);return l(q,u,v,n)};g.l=l;return g}();e=function(g,l,n,q){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,g);case 2:return b.call(this,g,l);case 3:return a.call(this,g,l,n);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.z(v,0,null)}return f.l(g,l,n,u)}throw Error("Invalid arity: "+arguments.length);};e.v=
3;e.B=f.B;e.A=d;e.g=c;e.h=b;e.j=a;e.l=f.l;return e}()};W2=function(a,b){return $CLJS.le(a)||$CLJS.me(a)?$CLJS.M.h(a,b):$CLJS.ne(a)?$CLJS.M.h($CLJS.Mg(a),b):null};X2=function(a,b){var c=$CLJS.A(b);for(a=$CLJS.A($CLJS.kg(a,b));;)if(a)c=$CLJS.D(c),a=$CLJS.D(a);else return c};Gpa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return null==e?d:$CLJS.U.j(d,f,e)},null==b?a:$CLJS.U.j(a,$CLJS.MP,b),$CLJS.vu(2,2,c))};
Hpa=function(){var a=$CLJS.Rp.h($CLJS.QZ,null),b=$CLJS.py(a,$CLJS.Naa,function(c){return $CLJS.Ro(c,$CLJS.Lg)});return function(){function c(f,g,l){return(g=$CLJS.A(b.j?b.j(f,g,l):b.call(null,f,g,l)))?new $CLJS.k(null,3,[$CLJS.dm,a,$CLJS.rl,f,$CLJS.du,g],null):null}function d(f){return e.j(f,$CLJS.Lg,$CLJS.Lg)}var e=null;e=function(f,g,l){switch(arguments.length){case 1:return d.call(this,f);case 3:return c.call(this,f,g,l)}throw Error("Invalid arity: "+arguments.length);};e.g=d;e.j=c;return e}()};
Y2=function(a){return Hpa()(a,$CLJS.Lg,$CLJS.Lg)};Z2=function(a,b,c,d){d=$CLJS.m(d)?d:$CLJS.Yr($CLJS.dm.g(a));if($CLJS.m(b)){var e=V2($CLJS.fv.g(b),c);$CLJS.m(e)?(e=$CLJS.bs(e,d),a=e.h?e.h(a,d):e.call(null,a,d)):a=null;return $CLJS.m(a)?a:V2($CLJS.dv.g(b),c)}return null};
Ipa=function(a,b){a=$CLJS.Qf(a);var c=$CLJS.M.h(a,$CLJS.dm),d=$CLJS.M.h(a,$CLJS.Xk);b=$CLJS.Qf(b);var e=$CLJS.M.j(b,$CLJS.du,$CLJS.nba),f=$CLJS.M.j(b,$CLJS.iba,!0),g=$CLJS.M.h(b,$CLJS.ov),l=$CLJS.M.j(b,$CLJS.mv,$CLJS.kv),n=Z2(a,$CLJS.ns.g(c),g,b);if($CLJS.m(n))return n;n=Z2(a,$CLJS.su.g(c),g,b);if($CLJS.m(n))return n;n=Z2(a,e.g?e.g(d):e.call(null,d),g,b);if($CLJS.m(n))return n;n=Z2(a,function(){var q=$CLJS.zp.g(c);return e.g?e.g(q):e.call(null,q)}(),g,b);if($CLJS.m(n))return n;n=Z2(a,$CLJS.ns.g(c),
l,b);if($CLJS.m(n))return n;n=Z2(a,$CLJS.su.g(c),l,b);if($CLJS.m(n))return n;d=Z2(a,e.g?e.g(d):e.call(null,d),l,b);if($CLJS.m(d))return d;d=Z2(a,function(){var q=$CLJS.zp.g(c);return e.g?e.g(q):e.call(null,q)}(),l,b);if($CLJS.m(d))return d;g=$CLJS.m(f)?Z2(a,e.g?e.g($CLJS.iv):e.call(null,$CLJS.iv),g,b):f;return $CLJS.m(g)?g:$CLJS.m(f)?Z2(a,e.g?e.g($CLJS.iv):e.call(null,$CLJS.iv),l,b):f};
Jpa=function(a,b,c){a=$CLJS.T;var d=$CLJS.oh.h;var e=$CLJS.nn.g(b);var f=$CLJS.Qf(b);var g=$CLJS.M.h(f,$CLJS.dm),l=$CLJS.Qf(c);f=$CLJS.M.h(l,$CLJS.ov);l=$CLJS.M.j(l,$CLJS.mv,$CLJS.kv);g=$CLJS.ns.g(g);f=V2($CLJS.lv.g(g),f);f=$CLJS.m(f)?f:V2($CLJS.lv.g(g),l);d=d.call($CLJS.oh,e,f);return new $CLJS.S(null,2,5,a,[d,Ipa(b,c)],null)};
Kpa=function(a){var b=$CLJS.Qf(a),c=$CLJS.M.h(b,$CLJS.rl);a=$CLJS.M.h(b,$CLJS.du);var d=$CLJS.Qf(null),e=$CLJS.M.j(d,$CLJS.jba,$CLJS.zk),f=$CLJS.M.j(d,$CLJS.lba,Jpa);return $CLJS.m(a)?$CLJS.Sb(function(g,l){var n=f.j?f.j(b,l,d):f.call(null,b,l,d),q=$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);l=$CLJS.U.j(l,$CLJS.zk,n);l=e.g?e.g(l):e.call(null,l);return $2(g,c,q,l)},null,a):null};Lpa=function(a){return $CLJS.qg.h($CLJS.vZ(a),$CLJS.wZ(a))};
$CLJS.a3=function(a,b){return $CLJS.Pu($CLJS.$_(a)?a:new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.rl,new $CLJS.k(null,2,[$CLJS.IG,$CLJS.p.g($CLJS.nL()),$CLJS.lk,$CLJS.BL(a)],null),a],null),new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.LL],null),b)};$CLJS.c3=function(){return $CLJS.Mm.h(b3,$CLJS.l_($CLJS.G([$CLJS.m_,$CLJS.kG,$CLJS.n_,function(a,b){return b3.g([$CLJS.p.g(a),"_",$CLJS.p.g(b)].join(""))}])))};
d3=function(a,b,c){var d=new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.Zt,null,$CLJS.rn,null],null),null),e=$CLJS.m(d.g?d.g(b):d.call(null,b))?$CLJS.Lu(2,c):$CLJS.Lu(1,c);b=$CLJS.m(d.g?d.g(b):d.call(null,b))?X2(2,c):X2(1,c);var f=$CLJS.J(b,0,null);return $CLJS.A(e)?$CLJS.p_.j(a,e,function(g){var l=$CLJS.U.j(g,f,null);return $CLJS.qe(g)?$CLJS.oh.j($CLJS.Lg,$CLJS.Pm.g($CLJS.Cb),l):$CLJS.oe(g)?$CLJS.aG($CLJS.Hb,l):l}):$CLJS.Em.h(a,f)};
Npa=function(a){return $CLJS.Sb(function(b,c){var d=$CLJS.J(c,0,null);$CLJS.J(c,1,null);return d3(b,Mpa,d)},a,Lpa(a))};
Opa=function(a){for(var b=0;;){var c=$CLJS.Td($CLJS.hZ.g(a),b);a:{var d=c;for(var e=$CLJS.Lg;;){var f=$CLJS.C($CLJS.rg.h(Fpa(),$CLJS.ug($CLJS.Mm.j(e3,$CLJS.C,$CLJS.nn),$CLJS.du.g(Y2(d)))));if($CLJS.m(f)){var g=f;f=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);var l=d3(d,f,g),n=$CLJS.YF($CLJS.YD);if($CLJS.m($CLJS.eG("metabase.lib.convert",n))){var q=$CLJS.OG.l($CLJS.G([$CLJS.TE,$CLJS.aj.l($CLJS.G([g]))])),u=$CLJS.OG,v=u.l,x=$CLJS.aj,y=x.l;var B=f;B=$CLJS.m(B)?B:Kpa(Y2(d));$CLJS.fG("metabase.lib.convert",n,
$CLJS.Y_("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.G([q,v.call(u,$CLJS.G([$CLJS.TE,y.call(x,$CLJS.G([B]))])),$CLJS.OG.l($CLJS.G([$CLJS.IE,$CLJS.Z_($CLJS.C($CLJS.Bz(d,l)))]))])),null)}if($CLJS.F.h(l,d))break a;d=l;e=$CLJS.kf.h(e,new $CLJS.S(null,2,5,$CLJS.T,[f,g],null))}else break a}}d=Npa(d);if($CLJS.F.h(c,d)){if($CLJS.F.h(b,$CLJS.E($CLJS.hZ.g(a))-1))return a;b+=1}else a=$CLJS.Rm.R(a,$CLJS.hZ,$CLJS.U,b,d)}};
Ppa=function(a){var b=$CLJS.c3();return $CLJS.Qm.h(function(c){return $CLJS.F.h($CLJS.vP.g(c),"__join")?$CLJS.Rm.j(c,$CLJS.vP,b):c},a)};Qpa=function(a){return"string"===typeof $CLJS.YP.g(a)?$CLJS.Em.h($CLJS.U.j(a,$CLJS.xZ,$CLJS.f0($CLJS.YP.g(a))),$CLJS.YP):a};g3=function(a,b){var c=$CLJS.M.j(a,b,f3);if($CLJS.F.h(c,f3))throw $CLJS.zj(["Unable to find ",$CLJS.aj.l($CLJS.G([b]))," in map."].join(""),new $CLJS.k(null,2,[Rpa,a,$CLJS.Zu,b],null));return c};
h3=function(){return $CLJS.Pm.g(function(a){var b=$CLJS.J(a,0,null);$CLJS.J(a,1,null);return $CLJS.xf(b)&&$CLJS.F.h($CLJS.qf(b),"lib")})};i3=function(a){return $CLJS.oh.j($CLJS.P,h3(),a)};j3=function(a){return $CLJS.Rf($CLJS.oh.j($CLJS.P,$CLJS.Mm.h(h3(),$CLJS.Pm.g(function(b){var c=$CLJS.J(b,0,null);$CLJS.J(b,1,null);return $CLJS.F.h(c,$CLJS.lk)})),a))};
l3=function(a){var b=$CLJS.A(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);var d=$CLJS.oh.j(new $CLJS.S(null,1,5,$CLJS.T,[a],null),$CLJS.rg.g($CLJS.k3),c);a=$CLJS.F.h(a,$CLJS.ZI)&&$CLJS.D(c)?$CLJS.kf.h($CLJS.ie(d),new $CLJS.k(null,1,[$CLJS.Pj,$CLJS.he(d)],null)):d;b=$CLJS.Rf(j3(b));return $CLJS.m(b)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nO,a,b],null):a};Spa=function(a){return $CLJS.oh.j($CLJS.Lg,$CLJS.Mm.h($CLJS.rg.g(function(b){return $CLJS.cG(b,$CLJS.TG)}),$CLJS.rg.g($CLJS.k3)),$CLJS.d0.g(a))};
m3=function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.hZ);a=$CLJS.C($CLJS.Sb(function(b,c){var d=$CLJS.J(b,0,null),e=$CLJS.J(b,1,null);b=$CLJS.T;var f=$CLJS.k3.g(c);d=$CLJS.m(d)?$CLJS.U.j(f,$CLJS.kR,d):f;d=$CLJS.m(e)?$CLJS.U.j(d,$CLJS.NP,Spa(e)):d;return new $CLJS.S(null,2,5,b,[d,$CLJS.oZ.g(c)],null)},null,a));return $CLJS.m($CLJS.TQ.g(a))?$CLJS.PN(a,new $CLJS.k(null,1,[$CLJS.TQ,$CLJS.uO],null)):a};
n3=function(a,b,c){var d=$CLJS.F.h($CLJS.E($CLJS.M.h(a,b)),1)?$CLJS.q_.j(a,b,$CLJS.Mm.h($CLJS.k3,$CLJS.C)):a;a=1<$CLJS.E($CLJS.M.h(a,b))?$CLJS.q_.j(d,b,function(e){return $CLJS.oh.j(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Lj],null),$CLJS.rg.g($CLJS.k3),e)}):d;return $CLJS.PN(a,$CLJS.Pf([b,c]))};Tpa=function(a){var b=$CLJS.xZ.g(a);return $CLJS.m(b)?$CLJS.U.j($CLJS.Em.h(a,$CLJS.xZ),$CLJS.YP,["card__",$CLJS.p.g(b)].join("")):a};
$2=function $2(a,b,c,d){var f=$CLJS.A(c);c=$CLJS.C(f);var g=$CLJS.D(f),l=W2(b,c);f=$CLJS.m(a)?a:$CLJS.ne(b)?$CLJS.Lg:$CLJS.pe(b)?$CLJS.P:$CLJS.ae(b);return $CLJS.m($CLJS.m(c)?$CLJS.jv.g($CLJS.fe(f)):c)?a:$CLJS.m(c)?(b=W2(f,c),d=$2.D?$2.D(b,l,g,d):$2.call(null,b,l,g,d),$CLJS.Be(c)&&$CLJS.ne(f)&&c>$CLJS.E(f)&&(b=$CLJS.ng(c-$CLJS.E(f),null),b=$CLJS.qg.h(f,b),f=null==f||$CLJS.we(f)?b:$CLJS.oh.h($CLJS.ae(f),b)),null==f||$CLJS.me(f)?$CLJS.U.j(f,c,d):$CLJS.le(f)?$CLJS.kf.h(f,d):$CLJS.R.h($CLJS.X,$CLJS.U.j($CLJS.Mg(f),
c,d))):$CLJS.oe(a)?(c=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.kba],null),$2.D?$2.D(f,b,c,d):$2.call(null,f,b,c,d)):$CLJS.m($CLJS.jv.g($CLJS.fe(f)))?$CLJS.kf.h(f,d):$CLJS.qe($CLJS.Rf(f))?f:$CLJS.K(new $CLJS.S(null,1,5,$CLJS.T,[d],null),new $CLJS.k(null,1,[$CLJS.jv,!0],null))};b3=function b3(a){switch(arguments.length){case 1:return b3.g(arguments[0]);case 2:return b3.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
b3.g=function(a){return b3.h(a,60)};b3.h=function(a,b){if((new TextEncoder).encode(a).length<=b)return a;var c;a:for(c=($CLJS.kW.str(a,0)>>>0).toString(16);;)if(8>$CLJS.E(c))c=["0",$CLJS.p.g(c)].join("");else break a;b=new Uint8Array(b-9);b=(new TextEncoder).encodeInto(a,b);return[a.substring(0,b.read),"_",$CLJS.p.g(c)].join("")};b3.v=2;
var f3=new $CLJS.N("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550),Mpa=new $CLJS.N("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475),o3=new $CLJS.N("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672),p3=new $CLJS.N("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782),Rpa=new $CLJS.N(null,"m","m",1632677161),q3=new $CLJS.N("metabase.lib.convert","aggregation",
"metabase.lib.convert/aggregation",108290790);var r3,s3,e3,Upa,Vpa,Wpa,Xpa,Ypa,Zpa,$pa,aqa,bqa,cqa;r3=$CLJS.P;s3=$CLJS.P;e3=new $CLJS.ni(null,new $CLJS.k(null,7,[$CLJS.NZ,null,$CLJS.wO,null,$CLJS.UO,null,$CLJS.OQ,null,$CLJS.FP,null,$CLJS.YR,null,$CLJS.MP,null],null),null);Upa=$CLJS.cj.g($CLJS.P);Vpa=$CLJS.cj.g($CLJS.P);Wpa=$CLJS.cj.g($CLJS.P);Xpa=$CLJS.cj.g($CLJS.P);Ypa=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.DH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));
$CLJS.t3=new $CLJS.vj($CLJS.Ui.h("metabase.lib.convert","-\x3epMBQL"),$CLJS.CH,$CLJS.Pj,Ypa,Upa,Vpa,Wpa,Xpa);$CLJS.t3.o(null,$CLJS.Pj,function(a){if($CLJS.qe(a)&&$CLJS.C(a)instanceof $CLJS.N){var b=$CLJS.he(a);var c=$CLJS.oe(b);a=c?$CLJS.ie(a):a;var d=$CLJS.A(a);a=$CLJS.C(d);d=$CLJS.D(d);b=$CLJS.fW($CLJS.oh.j(new $CLJS.S(null,2,5,$CLJS.T,[a,c?b:$CLJS.P],null),$CLJS.rg.g($CLJS.t3),d))}else b=a;return b});$CLJS.t3.o(null,$CLJS.MZ,function(a){return a});
$CLJS.t3.o(null,$CLJS.CZ,function(a){var b=$CLJS.t3.g($CLJS.MP.g(a)),c=$CLJS.Rf($CLJS.Qm.h(function(g){var l=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);return $CLJS.a3($CLJS.t3.g(g),l)},$CLJS.OQ.g(a))),d=s3;s3=$CLJS.oh.j($CLJS.P,$CLJS.Io(function(g,l){$CLJS.J(l,0,null);l=$CLJS.J(l,1,null);l=$CLJS.Qf(l);l=$CLJS.M.h(l,$CLJS.IG);return new $CLJS.S(null,2,5,$CLJS.T,[g,l],null)}),b);try{var e=Gpa(Qpa(a),b,$CLJS.G([$CLJS.OQ,c])),f=$CLJS.Sb(function(g,l){return $CLJS.Gb($CLJS.M.h(g,l))?g:$CLJS.Rm.j(g,l,$CLJS.t3)},
e,$CLJS.Fm.l(e3,$CLJS.MP,$CLJS.G([$CLJS.OQ])));return $CLJS.m($CLJS.UO.g(f))?$CLJS.Rm.j(f,$CLJS.UO,Ppa):f}finally{s3=d}});$CLJS.t3.o(null,$CLJS.cZ,function(a){a=$CLJS.Rm.j($CLJS.Rm.j(a,$CLJS.kZ,$CLJS.t3),$CLJS.hZ,$CLJS.t3);var b=$CLJS.m($CLJS.wO.g(a))?$CLJS.Rm.j(a,$CLJS.wO,function(c){return $CLJS.xe(c)?$CLJS.Qm.h($CLJS.t3,c):$CLJS.Oi.g(c)}):a;return $CLJS.Gb($CLJS.vP.g(a))?$CLJS.U.j(b,$CLJS.vP,"__join"):b});$CLJS.t3.o(null,$CLJS.xG,function(a){return $CLJS.Qm.h($CLJS.t3,a)});
$CLJS.t3.o(null,$CLJS.zG,function(a){return $CLJS.m($CLJS.Xk.g(a))?Opa($CLJS.Rm.j($CLJS.e0(a),$CLJS.hZ,function(b){return $CLJS.Qm.h($CLJS.t3,b)})):$CLJS.Ku(a,$CLJS.t3)});$CLJS.t3.o(null,$CLJS.DQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=$CLJS.oe(b)?new $CLJS.S(null,2,5,$CLJS.T,[a,b],null):new $CLJS.S(null,2,5,$CLJS.T,[b,a],null);b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.fW(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,a,b],null))});
$CLJS.t3.o(null,$CLJS.rl,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=$CLJS.PN(a,new $CLJS.k(null,3,[$CLJS.tJ,$CLJS.DG,$CLJS.BP,$CLJS.Ll,$CLJS.fS,$CLJS.uL],null));var c=$CLJS.U.j;var d=$CLJS.lk.g(a);$CLJS.m(d)||(d=$CLJS.DG.g(a),d=$CLJS.m(d)?d:$CLJS.BL(b));a=c.call($CLJS.U,a,$CLJS.lk,d);return $CLJS.fW(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.rl,a,b],null))});
$CLJS.t3.o(null,$CLJS.ZI,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.Pj.g(c);b=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZI,$CLJS.Em.h(c,$CLJS.Pj),$CLJS.Qm.h($CLJS.t3,b)],null);b=$CLJS.fW(b);return null!=a?$CLJS.kf.h(b,$CLJS.t3.g(a)):b});$CLJS.t3.o(null,$CLJS.AG,function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.fW(new $CLJS.S(null,3,5,$CLJS.T,[b,a,c],null))});
$CLJS.t3.o(null,$CLJS.MP,function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.fW(new $CLJS.S(null,3,5,$CLJS.T,[b,a,g3(s3,c)],null))});$CLJS.t3.o(null,$CLJS.nO,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);b=$CLJS.t3.g(b);var c=$CLJS.A(b);b=$CLJS.C(c);var d=$CLJS.D(c);c=$CLJS.C(d);d=$CLJS.D(d);return $CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[b,$CLJS.gn.l($CLJS.G([c,a]))],null),d)});Zpa=$CLJS.cj.g($CLJS.P);$pa=$CLJS.cj.g($CLJS.P);aqa=$CLJS.cj.g($CLJS.P);
bqa=$CLJS.cj.g($CLJS.P);cqa=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.DH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));$CLJS.k3=new $CLJS.vj($CLJS.Ui.h("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.CH,$CLJS.Pj,cqa,Zpa,$pa,aqa,bqa);
$CLJS.k3.o(null,$CLJS.Pj,function(a){if($CLJS.qe(a)&&$CLJS.C(a)instanceof $CLJS.N){a=$CLJS.A(a);var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);var d=$CLJS.D(c);if($CLJS.oe(a)){c=$CLJS.oh.h;b=new $CLJS.S(null,1,5,$CLJS.T,[b],null);var e=$CLJS.qg.h;d=$CLJS.rg.h($CLJS.k3,d);a=j3(a);a=c.call($CLJS.oh,b,e.call($CLJS.qg,d,$CLJS.m(a)?new $CLJS.S(null,1,5,$CLJS.T,[a],null):null))}else a=$CLJS.oh.h(new $CLJS.S(null,1,5,$CLJS.T,[b],null),$CLJS.rg.h($CLJS.k3,$CLJS.nf(a,d)))}else a=$CLJS.oe(a)?$CLJS.Ku(i3(a),$CLJS.k3):
a;return a});for(var u3=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[q3,p3],null)),v3=null,w3=0,x3=0;;)if(x3<w3){var dqa=v3.$(null,x3);$CLJS.lV(dqa,o3);x3+=1}else{var y3=$CLJS.A(u3);if(y3){var z3=y3;if($CLJS.re(z3)){var A3=$CLJS.$c(z3),eqa=$CLJS.ad(z3),fqa=A3,gqa=$CLJS.E(A3);u3=eqa;v3=fqa;w3=gqa}else{var hqa=$CLJS.C(z3);$CLJS.lV(hqa,o3);u3=$CLJS.D(z3);v3=null;w3=0}x3=0}else break}
for(var B3=$CLJS.A(new $CLJS.S(null,12,5,$CLJS.T,[$CLJS.lD,$CLJS.CJ,$CLJS.eK,$CLJS.MI,$CLJS.Zl,$CLJS.uK,$CLJS.rk,$CLJS.mJ,$CLJS.YJ,$CLJS.zK,$CLJS.sK,$CLJS.NK],null)),C3=null,D3=0,E3=0;;)if(E3<D3){var iqa=C3.$(null,E3);$CLJS.lV(iqa,q3);E3+=1}else{var F3=$CLJS.A(B3);if(F3){var G3=F3;if($CLJS.re(G3)){var H3=$CLJS.$c(G3),jqa=$CLJS.ad(G3),kqa=H3,lqa=$CLJS.E(H3);B3=jqa;C3=kqa;D3=lqa}else{var mqa=$CLJS.C(G3);$CLJS.lV(mqa,q3);B3=$CLJS.D(G3);C3=null;D3=0}E3=0}else break}
for(var I3=$CLJS.A($CLJS.Hg([$CLJS.mm,$CLJS.xu,$CLJS.nm,$CLJS.PK,$CLJS.ZI,$CLJS.QJ,$CLJS.tI,$CLJS.RK,$CLJS.ZH,$CLJS.oJ,$CLJS.jI,$CLJS.GJ,$CLJS.VJ,$CLJS.mI,$CLJS.iJ,$CLJS.rJ,$CLJS.ZD,$CLJS.FI,$CLJS.DJ,$CLJS.uI,$CLJS.cK,$CLJS.EI,$CLJS.YH,$CLJS.aK,$CLJS.wK,$CLJS.gK,$CLJS.DI,$CLJS.SK,$CLJS.dK,$CLJS.LK,$CLJS.oI,$CLJS.aI,$CLJS.VK,$CLJS.ZW,$CLJS.OJ,$CLJS.jD,$CLJS.BK,$CLJS.GK,$CLJS.YK,$CLJS.Ru,$CLJS.$u],!0)),J3=null,K3=0,L3=0;;)if(L3<K3){var nqa=J3.$(null,L3);$CLJS.lV(nqa,p3);L3+=1}else{var M3=$CLJS.A(I3);
if(M3){var N3=M3;if($CLJS.re(N3)){var O3=$CLJS.$c(N3),oqa=$CLJS.ad(N3),pqa=O3,qqa=$CLJS.E(O3);I3=oqa;J3=pqa;K3=qqa}else{var rqa=$CLJS.C(N3);$CLJS.lV(rqa,p3);I3=$CLJS.D(N3);J3=null;K3=0}L3=0}else break}$CLJS.k3.o(null,o3,function(a){return l3(a)});$CLJS.k3.o(null,$CLJS.zG,function(a){return $CLJS.oh.j($CLJS.P,$CLJS.Mm.h(h3(),$CLJS.rg.g(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[c,$CLJS.k3.g(b)],null)})),a)});
$CLJS.k3.o(null,$CLJS.MP,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);return $CLJS.oe(b)?(a=j3(b),c=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.MP,g3(r3,c)],null),$CLJS.m(a)?$CLJS.kf.h(c,a):c):a});$CLJS.k3.o(null,$CLJS.xG,function(a){return $CLJS.Qm.h($CLJS.k3,a)});
$CLJS.k3.o(null,$CLJS.DQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=null==b||$CLJS.oe(b)?new $CLJS.S(null,2,5,$CLJS.T,[b,a],null):new $CLJS.S(null,2,5,$CLJS.T,[a,b],null);b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,$CLJS.k3.g(a),j3(b)],null)});
$CLJS.k3.o(null,$CLJS.rl,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);b=j3($CLJS.PN(b,new $CLJS.k(null,3,[$CLJS.DG,$CLJS.tJ,$CLJS.Ll,$CLJS.BP,$CLJS.uL,$CLJS.fS],null)));return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.rl,a,b],null)});
$CLJS.k3.o(null,$CLJS.cZ,function(a){var b=i3(a);a=0==$CLJS.vP.g(a).lastIndexOf("__join",0)?$CLJS.Em.h(b,$CLJS.vP):b;return $CLJS.gn.l($CLJS.G([$CLJS.Ku($CLJS.Em.l(a,$CLJS.hZ,$CLJS.G([$CLJS.kZ])),$CLJS.k3),n3($CLJS.li(a,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.kZ],null)),$CLJS.kZ,$CLJS.$R),m3(a)]))});
$CLJS.k3.o(null,$CLJS.CZ,function(a){var b=r3;r3=$CLJS.oh.j($CLJS.P,$CLJS.Io(function(c,d){$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);d=$CLJS.Qf(d);d=$CLJS.M.h(d,$CLJS.IG);return new $CLJS.S(null,2,5,$CLJS.T,[d,c],null)}),$CLJS.MP.g(a));try{return $CLJS.Sb(function(c,d){return $CLJS.q_.j(c,d,$CLJS.k3)},n3($CLJS.q_.j($CLJS.q_.j(Tpa(i3(a)),$CLJS.MP,function(c){return $CLJS.Qm.h(l3,c)}),$CLJS.OQ,function(c){return $CLJS.oh.h($CLJS.P,function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=
$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.be(l,u),x=$CLJS.k3.g(v);v=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.a0(v),$CLJS.F.h($CLJS.rl,$CLJS.C(x))?$CLJS.Zd(x):x],null);q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}q=$CLJS.C(g);l=$CLJS.k3.g(q);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.a0(q),$CLJS.F.h($CLJS.rl,$CLJS.C(l))?$CLJS.Zd(l):l],null),f($CLJS.zd(g)))}return null}},
null,null)}(c)}())}),$CLJS.NZ,$CLJS.IO),$CLJS.Fm.l(e3,$CLJS.MP,$CLJS.G([$CLJS.NZ,$CLJS.OQ])))}finally{r3=b}});$CLJS.k3.o(null,$CLJS.IZ,function(a){return $CLJS.Ku(i3(a),$CLJS.k3)});
$CLJS.k3.o(null,$CLJS.MZ,function(a){var b=i3(a),c=$CLJS.NO.g(b),d=m3(b),e=$CLJS.F.h($CLJS.vz.g($CLJS.$d($CLJS.hZ.g(a))),$CLJS.IZ)?$CLJS.TQ:$CLJS.uO;a=$CLJS.gn.l;b=$CLJS.Ku($CLJS.Em.l(b,$CLJS.hZ,$CLJS.G([$CLJS.NO])),$CLJS.k3);d=$CLJS.Pf([$CLJS.Xk,e,e,d]);c=$CLJS.A(c)?$CLJS.U.j(d,$CLJS.NO,c):d;return a.call($CLJS.gn,$CLJS.G([b,c]))});