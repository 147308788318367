var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.breakout.js");require("./metabase.lib.expression.js");require("./metabase.lib.field.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var X7,Y7,pta,a8,qta,d8,rta,sta,tta,uta,vta,wta,xta,i8,yta,k8,l8,zta,f8,e8,m8,n8;X7=function(a,b){return null==b||$CLJS.Ie(a,$CLJS.lk)?a:$CLJS.U.j(a,$CLJS.lk,b)};
Y7=function(a){return function(b){var c=$CLJS.ig($CLJS.oi);return function(){function d(l,n){var q=a.g?a.g(n):a.call(null,n);if($CLJS.Ie($CLJS.r(c),q))return l;c.Re(null,$CLJS.kf.h(c.$b(null),q));return b.h?b.h(l,n):b.call(null,l,n)}function e(l){return b.g?b.g(l):b.call(null,l)}function f(){return b.A?b.A():b.call(null)}var g=null;g=function(l,n){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,n)}throw Error("Invalid arity: "+arguments.length);
};g.A=f;g.g=e;g.h=d;return g}()}};$CLJS.Z7=function(a,b){return $CLJS.G1.h(a,b instanceof $CLJS.N?b:$CLJS.Wj.g(b))};$CLJS.$7=function(a){a=$CLJS.H1.g(a);return $CLJS.m(a)?new $CLJS.k(null,2,[$CLJS.vz,$CLJS.GV,$CLJS.Wj,a],null):null};pta=function(a,b,c){return $CLJS.oh.j($CLJS.Lg,$CLJS.VG(function(d){return $CLJS.N5(a,b,d,new $CLJS.k(null,2,[$CLJS.s1,c,$CLJS.n1,!1],null))}),$CLJS.UO.g($CLJS.V0(a,b)))};
a8=function(a,b,c){return $CLJS.oh.j($CLJS.Lg,$CLJS.VG(function(d){return $CLJS.rg.h($CLJS.zz($CLJS.Z3,d,c),$CLJS.$0(a,b,d))}),$CLJS.UO.g($CLJS.V0(a,b)))};$CLJS.b8=function(a,b){return $CLJS.h5.h(a,$CLJS.Ie(b,$CLJS.z4)?$CLJS.z4.g(b):b)};$CLJS.c8=function(a,b){var c=$CLJS.Rf($CLJS.FP.g($CLJS.V0(a,b)));return null==c?null:$CLJS.Qm.h(function(d){return $CLJS.U.j($CLJS.$0(a,b,d),$CLJS.g0,$CLJS.F0)},c)};
qta=function(a,b,c){var d=$CLJS.a0(c);return $CLJS.U.l($CLJS.$0(a,b,c),$CLJS.g0,$CLJS.x0,$CLJS.G([$CLJS.V,d,$CLJS.TI,d]))};d8=function(a,b){var c=$CLJS.P5(b);if($CLJS.m(c))a=c;else a:{var d=$CLJS.Qf(b);c=$CLJS.M.h(d,$CLJS.iR);d=$CLJS.M.h(d,$CLJS.k0);if($CLJS.m($CLJS.m(c)?d:c)&&(d=$CLJS.Y5(a,d),$CLJS.m(d))){d=$CLJS.V.g(d);a=$CLJS.V.g($CLJS.s0(a,c));a=$CLJS.xa.j?$CLJS.xa.j("%s__via__%s",d,a):$CLJS.xa.call(null,"%s__via__%s",d,a);break a}a=null}return $CLJS.m(a)?$CLJS.Y3(a,$CLJS.V.g(b)):$CLJS.V.g(b)};
$CLJS.c4=function(a,b){b=$CLJS.M5(a,b);return $CLJS.m(b)?$CLJS.W0(a,b,$CLJS.U,$CLJS.G([$CLJS.T5,e8?e8(a,b):f8.call(null,a,b)])):a};
rta=function(a,b){a=$CLJS.V0(a,b);a=$CLJS.Qf(a);var c=$CLJS.M.h(a,$CLJS.vz),d=$CLJS.M.h(a,$CLJS.xZ);b=$CLJS.T5.g(a);if($CLJS.m(b))return b;var e=$CLJS.oZ.g(a);if($CLJS.m(e)&&$CLJS.m(function(){var g=$CLJS.F.h(c,$CLJS.IZ);return g?g:d}())){var f=function(){var g=c instanceof $CLJS.N?c.S:null;switch(g){case "mbql.stage/native":return $CLJS.E0;case "mbql.stage/mbql":return $CLJS.C0;default:throw Error(["No matching clause: ",$CLJS.p.g(g)].join(""));}}();return $CLJS.Rf(function(){return function n(l){return new $CLJS.yf(null,
function(){for(;;){var q=$CLJS.A(l);if(q){if($CLJS.re(q)){var u=$CLJS.$c(q),v=$CLJS.E(u),x=$CLJS.Bf(v);a:for(var y=0;;)if(y<v){var B=$CLJS.be(u,y);B=$CLJS.gn.l($CLJS.G([new $CLJS.k(null,2,[$CLJS.t0,$CLJS.V.g(B),$CLJS.A0,$CLJS.V.g(B)],null),B,new $CLJS.k(null,1,[$CLJS.g0,f],null)]));x.add(B);y+=1}else{u=!0;break a}return u?$CLJS.Ef($CLJS.Gf(x),n($CLJS.ad(q))):$CLJS.Ef($CLJS.Gf(x),null)}x=$CLJS.C(q);return $CLJS.nf($CLJS.gn.l($CLJS.G([new $CLJS.k(null,2,[$CLJS.t0,$CLJS.V.g(x),$CLJS.A0,$CLJS.V.g(x)],
null),x,new $CLJS.k(null,1,[$CLJS.g0,f],null)])),n($CLJS.zd(q)))}return null}},null,null)}($CLJS.d0.g(e))}())}return null};
sta=function(a,b,c){return $CLJS.Rf(function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);return function(){for(var v=0;;)if(v<n){var x=$CLJS.be(l,v),y=q,B=$CLJS.U,H=B.l,I=x,Q=$CLJS.V.g(x);x=$CLJS.V.g(x);x=c.g?c.g(x):c.call(null,x);B=H.call(B,I,$CLJS.g0,$CLJS.F0,$CLJS.G([$CLJS.t0,Q,$CLJS.A0,x]));y.add(B);v+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}var u=
$CLJS.C(g);return $CLJS.nf($CLJS.U.l(u,$CLJS.g0,$CLJS.F0,$CLJS.G([$CLJS.t0,$CLJS.V.g(u),$CLJS.A0,function(){var v=$CLJS.V.g(u);return c.g?c.g(v):c.call(null,v)}()])),f($CLJS.zd(g)))}return null}},null,null)}($CLJS.c8(a,b))}())};
tta=function(a,b,c){return $CLJS.Rf(function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);return function(){for(var v=0;;)if(v<n){var x=$CLJS.be(l,v),y=q,B=$CLJS.U,H=B.l,I=x,Q=$CLJS.V.g(x);x=$CLJS.V.g(x);x=c.g?c.g(x):c.call(null,x);B=H.call(B,I,$CLJS.g0,$CLJS.G0,$CLJS.G([$CLJS.t0,Q,$CLJS.A0,x]));y.add(B);v+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}var u=
$CLJS.C(g);return $CLJS.nf($CLJS.U.l(u,$CLJS.g0,$CLJS.G0,$CLJS.G([$CLJS.t0,$CLJS.V.g(u),$CLJS.A0,function(){var v=$CLJS.V.g(u);return c.g?c.g(v):c.call(null,v)}()])),f($CLJS.zd(g)))}return null}},null,null)}($CLJS.g8.h(a,b))}())};
uta=function(a,b,c){var d=$CLJS.V0(a,b);if($CLJS.m(d)){d=$CLJS.Qf(d);var e=$CLJS.M.h(d,$CLJS.wO);return $CLJS.Rf(function(){return function l(g){return new $CLJS.yf(null,function(){for(;;){var n=$CLJS.A(g);if(n){if($CLJS.re(n)){var q=$CLJS.$c(n),u=$CLJS.E(q),v=$CLJS.Bf(u);return function(){for(var I=0;;)if(I<u){var Q=$CLJS.be(q,I),Y=$CLJS.J(Q,0,null),aa=function(){var qa=Y;qa=qa instanceof $CLJS.N?qa.S:null;switch(qa){case "field":return $CLJS.D0;case "expression":return $CLJS.x0;default:throw Error(["No matching clause: ",
$CLJS.p.g(qa)].join(""));}}(),ha=$CLJS.$0(a,b,Q);$CLJS.Ff(v,$CLJS.U.l(ha,$CLJS.g0,aa,$CLJS.G([$CLJS.t0,$CLJS.y1.j(a,b,ha),$CLJS.A0,function(){var qa=d8(a,ha);return c.g?c.g(qa):c.call(null,qa)}()])));I+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(v),l($CLJS.ad(n))):$CLJS.Ef($CLJS.Gf(v),null)}var x=$CLJS.C(n),y=$CLJS.J(x,0,null),B=function(){var I=y;I=I instanceof $CLJS.N?I.S:null;switch(I){case "field":return $CLJS.D0;case "expression":return $CLJS.x0;default:throw Error(["No matching clause: ",$CLJS.p.g(I)].join(""));
}}(),H=$CLJS.$0(a,b,x);return $CLJS.nf($CLJS.U.l(H,$CLJS.g0,B,$CLJS.G([$CLJS.t0,$CLJS.y1.j(a,b,H),$CLJS.A0,function(){var I=d8(a,H);return c.g?c.g(I):c.call(null,I)}()])),l($CLJS.zd(n)))}return null}},null,null)}(e)}())}return null};vta=function(a,b,c){return $CLJS.Rf($CLJS.oh.j($CLJS.Lg,$CLJS.VG(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)}),new $CLJS.S(null,2,5,$CLJS.T,[sta,tta],null)))};
wta=function(a,b,c){var d=$CLJS.M5(a,b);return $CLJS.m(d)?$CLJS.Rf(function(){return function g(f){return new $CLJS.yf(null,function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.re(l)){var n=$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Bf(q);return function(){for(var y=0;;)if(y<q){var B=$CLJS.be(n,y);var H=$CLJS.Om.h($CLJS.A0,$CLJS.t0)(B);H=$CLJS.m(H)?H:$CLJS.y1.j(a,b,B);B=$CLJS.Em.h($CLJS.U.l(B,$CLJS.g0,$CLJS.u0,$CLJS.G([$CLJS.t0,H,$CLJS.A0,c.g?c.g(H):c.call(null,H)])),$CLJS.V5);u.add(B);y+=1}else return!0}()?
$CLJS.Ef($CLJS.Gf(u),g($CLJS.ad(l))):$CLJS.Ef($CLJS.Gf(u),null)}var v=$CLJS.C(l),x=function(){var y=$CLJS.Om.h($CLJS.A0,$CLJS.t0)(v);return $CLJS.m(y)?y:$CLJS.y1.j(a,b,v)}();return $CLJS.nf($CLJS.Em.h($CLJS.U.l(v,$CLJS.g0,$CLJS.u0,$CLJS.G([$CLJS.t0,x,$CLJS.A0,c.g?c.g(x):c.call(null,x)])),$CLJS.V5),g($CLJS.zd(l)))}return null}},null,null)}(e8?e8(a,d):f8.call(null,a,d))}()):null};
xta=function(a,b,c,d){return $CLJS.m(c)?(c=$CLJS.Y0(a,c),$CLJS.m(c)?$CLJS.Rf($CLJS.Qm.h(function(e){return $CLJS.Em.h(e,$CLJS.b4)},$CLJS.N5(a,b,c,new $CLJS.k(null,2,[$CLJS.s1,d,$CLJS.n1,!1],null)))):null):null};
i8=function(a,b,c){return $CLJS.Rf(function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);return function(){for(var v=0;;)if(v<n){var x=$CLJS.be(l,v),y=q;var B=$CLJS.DG.g(x);var H=$CLJS.U.l,I=x,Q=$CLJS.V.g(x);x=$CLJS.V.g(x);x=c.g?c.g(x):c.call(null,x);B=X7(H.call($CLJS.U,I,$CLJS.g0,$CLJS.x0,$CLJS.G([$CLJS.t0,Q,$CLJS.A0,x])),$CLJS.m(B)?B:$CLJS.ol);y.add(B);v+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(q),
f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}var u=$CLJS.C(g);return $CLJS.nf(function(){var v=$CLJS.DG.g(u),x=$CLJS.U.l,y=u,B=$CLJS.V.g(u);var H=$CLJS.V.g(u);H=c.g?c.g(H):c.call(null,H);return X7(x.call($CLJS.U,y,$CLJS.g0,$CLJS.x0,$CLJS.G([$CLJS.t0,B,$CLJS.A0,H])),$CLJS.m(v)?v:$CLJS.ol)}(),f($CLJS.zd(g)))}return null}},null,null)}(h8.h(a,b))}())};
yta=function(a,b,c,d){var e=$CLJS.oh.j($CLJS.oi,$CLJS.rg.g($CLJS.k0),c);return $CLJS.oh.j($CLJS.Lg,$CLJS.Mm.l($CLJS.tg($CLJS.b4),Y7($CLJS.b4),$CLJS.rg.g(function(f){var g=$CLJS.Qf(f);f=$CLJS.M.h(g,$CLJS.Hk);g=$CLJS.M.h(g,$CLJS.b4);return $CLJS.U.j($CLJS.s0(a,g),j8,f)}),$CLJS.G([$CLJS.Pm.g(function(f){return $CLJS.Ie(e,$CLJS.k0.g(f))}),Y7($CLJS.k0),$CLJS.VG(function(f){f=$CLJS.Qf(f);var g=$CLJS.M.h(f,$CLJS.k0),l=$CLJS.M.h(f,j8);f=$CLJS.X0(a,g);return function u(q){return new $CLJS.yf(null,function(){for(;;){var v=
$CLJS.A(q);if(v){if($CLJS.re(v)){var x=$CLJS.$c(v),y=$CLJS.E(x),B=$CLJS.Bf(y);return function(){for(var Q=0;;)if(Q<y){var Y=$CLJS.be(x,Q),aa=$CLJS.U.l(Y,$CLJS.iR,l,$CLJS.G([$CLJS.g0,$CLJS.y0,$CLJS.t0,$CLJS.V.g(Y)]));Y=B;var ha=$CLJS.U,qa=ha.j,Ea=aa;aa=d8(a,aa);aa=d.g?d.g(aa):d.call(null,aa);ha=qa.call(ha,Ea,$CLJS.A0,aa);Y.add(ha);Q+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(B),u($CLJS.ad(v))):$CLJS.Ef($CLJS.Gf(B),null)}var H=$CLJS.C(v),I=$CLJS.U.l(H,$CLJS.iR,l,$CLJS.G([$CLJS.g0,$CLJS.y0,$CLJS.t0,$CLJS.V.g(H)]));
return $CLJS.nf($CLJS.U.j(I,$CLJS.A0,function(){var Q=d8(a,I);return d.g?d.g(Q):d.call(null,Q)}()),u($CLJS.zd(v)))}return null}},null,null)}($CLJS.D1.D(a,b,f,new $CLJS.k(null,2,[$CLJS.s1,d,$CLJS.n1,!1],null)))})])),c)};
k8=function(a,b,c){var d=$CLJS.Qf(c),e=$CLJS.M.h(d,$CLJS.s1);c=wta(a,b,e);if($CLJS.m(c))return c;c=$CLJS.V0(a,b);c=$CLJS.Qf(c);var f=$CLJS.M.h(c,$CLJS.YP),g=$CLJS.M.h(c,$CLJS.xZ),l=$CLJS.m(f)?function(){var n=$CLJS.X0(a,f);return $CLJS.N5(a,b,n,d)}():null;if($CLJS.m(l))return l;g=$CLJS.m(g)?xta(a,b,g,e):null;return $CLJS.m(g)?g:function u(q){return new $CLJS.yf(null,function(){for(;;){var v=$CLJS.A(q);if(v){if($CLJS.re(v)){var x=$CLJS.$c(v),y=$CLJS.E(x),B=$CLJS.Bf(y);return function(){for(var I=0;;)if(I<
y){var Q=$CLJS.be(x,I),Y=B,aa=$CLJS.U,ha=aa.l,qa=Q,Ea=$CLJS.V.g(Q);Q=$CLJS.V.g(Q);Q=e.g?e.g(Q):e.call(null,Q);aa=ha.call(aa,qa,$CLJS.g0,$CLJS.E0,$CLJS.G([$CLJS.t0,Ea,$CLJS.A0,Q]));Y.add(aa);I+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(B),u($CLJS.ad(v))):$CLJS.Ef($CLJS.Gf(B),null)}var H=$CLJS.C(v);return $CLJS.nf($CLJS.U.l(H,$CLJS.g0,$CLJS.E0,$CLJS.G([$CLJS.t0,$CLJS.V.g(H),$CLJS.A0,function(){var I=$CLJS.V.g(H);return e.g?e.g(I):e.call(null,I)}()])),u($CLJS.zd(v)))}return null}},null,null)}($CLJS.d0.g($CLJS.oZ.g(c)))};
l8=function(a,b){var c=$CLJS.J(a,0,null);$CLJS.J(a,1,null);var d=$CLJS.J(a,2,null);switch(c instanceof $CLJS.N?c.S:null){case "field":if("number"===typeof d||"string"===typeof d)return $CLJS.F.h(d,$CLJS.Hk.g(b));throw $CLJS.zj("unknown type of :field ref in lib.stage/ref-to?",new $CLJS.k(null,2,[$CLJS.iS,a,$CLJS.Bk,b],null));case "expression":return $CLJS.F.h(d,$CLJS.V.g(b));default:throw $CLJS.zj("unknown clause in lib.stage/ref-to?",new $CLJS.k(null,2,[$CLJS.iS,a,$CLJS.Bk,b],null));}};
zta=function(a,b,c){var d=$CLJS.FP.g($CLJS.V0(a,b));return $CLJS.m(d)?function g(f){return new $CLJS.yf(null,function(){for(;;){var l=$CLJS.A(f);if(l){var n=l;if($CLJS.re(n)){var q=$CLJS.$c(n),u=$CLJS.E(q),v=$CLJS.Bf(u);return function(){for(var y=0;;)if(y<u){var B=$CLJS.be(q,y);$CLJS.Ff(v,function(){var H=$CLJS.vW(function(Y,aa){return function(ha){return l8(ha,aa)}}(y,B,q,u,v,n,l,d,d),d);if($CLJS.m(H)){var I=$CLJS.Q5(H);H=$CLJS.$7(H);H=$CLJS.Qf(H);H=$CLJS.M.h(H,$CLJS.Wj);var Q=B;I=$CLJS.m(I)?$CLJS.b8(Q,
I):Q;return $CLJS.m(H)?$CLJS.Z7(I,H):I}return B}());y+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(v),g($CLJS.ad(n))):$CLJS.Ef($CLJS.Gf(v),null)}var x=$CLJS.C(n);return $CLJS.nf(function(){var y=$CLJS.vW(function(I){return function(Q){return l8(Q,I)}}(x,n,l,d,d),d);if($CLJS.m(y)){var B=$CLJS.Q5(y);y=$CLJS.$7(y);y=$CLJS.Qf(y);y=$CLJS.M.h(y,$CLJS.Wj);var H=x;B=$CLJS.m(B)?$CLJS.b8(H,B):H;return $CLJS.m(y)?$CLJS.Z7(B,y):B}return x}(),g($CLJS.zd(n)))}return null}},null,null)}(c):c};
f8=function(a){switch(arguments.length){case 2:return e8(arguments[0],arguments[1]);case 3:return m8(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};e8=function(a,b){return m8(a,b,$CLJS.c3())};
m8=function(a,b,c){var d=rta(a,b);if($CLJS.m(d))return d;a=$CLJS.c4(a,b);d=vta(a,b,c);var e=uta(a,b,c);return $CLJS.m(d)?$CLJS.oh.h(d,e):$CLJS.m(e)?($CLJS.Ci(e),$CLJS.oh.j($CLJS.Lg,Y7(function(f){return $CLJS.Em.l(f,$CLJS.H0,$CLJS.G([$CLJS.g0,$CLJS.E1,$CLJS.A0]))}),$CLJS.qg.h(e,a8(a,b,c)))):$CLJS.qg.l(k8(a,b,new $CLJS.k(null,2,[$CLJS.n1,!1,$CLJS.s1,c],null)),i8(a,b,c),$CLJS.G([a8(a,b,c)]))};
n8=function n8(a){switch(arguments.length){case 2:return n8.h(arguments[0],arguments[1]);case 3:return n8.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};n8.h=function(a,b){return n8.j(a,-1,b)};n8.j=function(a,b,c){return $CLJS.z1.j(a,b,$CLJS.Oi.g(c))};n8.v=3;
$CLJS.g8=function g8(a){switch(arguments.length){case 1:return g8.g(arguments[0]);case 2:return g8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.g8.g=function(a){return $CLJS.g8.h(a,-1)};
$CLJS.g8.h=function(a,b){var c=$CLJS.Rf($CLJS.MP.g($CLJS.V0(a,b)));return null==c?null:$CLJS.oh.j($CLJS.Lg,$CLJS.rg.g(function(d){var e=$CLJS.$0(a,b,d),f=$CLJS.U.l,g=$CLJS.DG.g(e);return f.call($CLJS.U,X7(e,$CLJS.m(g)?g:$CLJS.ol),$CLJS.g0,$CLJS.G0,$CLJS.G([$CLJS.E1,$CLJS.IG.g($CLJS.Zd(d))]))}),c)};$CLJS.g8.v=2;
var h8=function h8(a){switch(arguments.length){case 1:return h8.g(arguments[0]);case 2:return h8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};h8.g=function(a){return h8.h(a,-1)};h8.h=function(a,b){var c=$CLJS.Rf($CLJS.OQ.g($CLJS.V0(a,b)));return null==c?null:$CLJS.Qm.h($CLJS.zz(qta,a,b),c)};h8.v=2;
var j8=new $CLJS.N("metabase.lib.stage","source-field-id","metabase.lib.stage/source-field-id",-962431705),o8=new $CLJS.N("metabase.lib.stage","stage","metabase.lib.stage/stage",1448689281);$CLJS.Bqa={};$CLJS.lV($CLJS.CZ,o8);$CLJS.lV($CLJS.IZ,o8);$CLJS.S3.o(null,$CLJS.CZ,function(a){return $CLJS.R3(a,new $CLJS.k(null,2,[$CLJS.MP,$CLJS.bg($CLJS.Qm,$CLJS.S3),$CLJS.NZ,$CLJS.bg($CLJS.Qm,$CLJS.S3)],null))});
$CLJS.D1.o(null,o8,function(a,b,c,d){var e=$CLJS.Qf(d);c=$CLJS.M.h(e,$CLJS.s1);d=$CLJS.M.h(e,$CLJS.n1);a=$CLJS.c4(a,b);e=$CLJS.Qf(e);var f=$CLJS.M.h(e,$CLJS.s1),g=$CLJS.M.h(e,$CLJS.q1),l=$CLJS.M.h(e,$CLJS.v1);e=$CLJS.qg.l(k8(a,b,e),$CLJS.m(l)?i8(a,b,f):null,$CLJS.G([$CLJS.m(g)?pta(a,b,f):null]));return zta(a,b,$CLJS.qg.h(e,$CLJS.m(d)?yta(a,b,e,c):null))});$CLJS.Z0.o(null,o8,function(a,b){return e8(a,b)});$CLJS.w1.o(null,$CLJS.IZ,function(){return $CLJS.VH("Native query")});
var Ata=new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.YP,$CLJS.xZ,$CLJS.MP,$CLJS.FP,$CLJS.NZ,$CLJS.YR,$CLJS.rS],null);
$CLJS.w1.o(null,$CLJS.CZ,function(a,b,c,d){var e=$CLJS.c4(a,b);a=$CLJS.Rf(function(){return $CLJS.bv.h(", ",$CLJS.Pm.h($CLJS.PF,function(){return function l(g){return new $CLJS.yf(null,function(){for(;;){var n=$CLJS.A(g);if(n){if($CLJS.re(n)){var q=$CLJS.$c(n),u=$CLJS.E(q),v=$CLJS.Bf(u);a:for(var x=0;;)if(x<u){var y=$CLJS.be(q,x);y=n8.j(e,b,y);v.add(y);x+=1}else{q=!0;break a}return q?$CLJS.Ef($CLJS.Gf(v),l($CLJS.ad(n))):$CLJS.Ef($CLJS.Gf(v),null)}v=$CLJS.C(n);return $CLJS.nf(n8.j(e,b,v),l($CLJS.zd(n)))}return null}},
null,null)}(Ata)}()))}());if($CLJS.m(a))return a;a=$CLJS.M5(e,b);return $CLJS.m(a)?$CLJS.a1.D(e,a,$CLJS.V0(e,a),d):null});