var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.formatting.internal.numbers.js");require("./metabase.shared.formatting.internal.numbers_core.js");require("./metabase.util.js");
'use strict';var S4=function(a){var b=$CLJS.gn.l;var c=$CLJS.Qf(a);var d=$CLJS.M.h(c,$CLJS.L4);c=$CLJS.M.h(c,$CLJS.M4);if($CLJS.m($CLJS.m(d)?$CLJS.F.h(c,"currency"):d)){c=$CLJS.G4.g;d=$CLJS.Oi.g(d);var e=$CLJS.r($CLJS.D4);d=e.g?e.g(d):e.call(null,d);c=c.call($CLJS.G4,d);c=new $CLJS.k(null,2,[$CLJS.K4,c,$CLJS.N4,c],null)}else c=new $CLJS.k(null,1,[$CLJS.N4,2],null);b=b.call($CLJS.gn,$CLJS.G([c,a]));return $CLJS.m($CLJS.O4.g(a))?$CLJS.U.l($CLJS.Em.h(b,$CLJS.O4),$CLJS.N4,$CLJS.O4.g(a),$CLJS.G([$CLJS.K4,
$CLJS.O4.g(a)])):b},T4=function(a,b){if(null!=a&&null!=a.Ff)a=a.Ff(a,b);else{var c=T4[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=T4._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("NumberFormatter.format-number-basic",a);}return a},jra=function(a,b){var c=$CLJS.hF(a.formatToParts(b),$CLJS.G([new $CLJS.k(null,1,[$CLJS.fk,!0],null)]));a=$CLJS.m($CLJS.Xf(function(d){return $CLJS.F.h($CLJS.Xk.g(d),"exponentMinusSign")},c))?c:function(){var d=$CLJS.Bi(function(f){return $CLJS.ki.h($CLJS.Xk.g(f),
"exponentInteger")},c),e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return $CLJS.qg.l(e,new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.Xk,"exponentPlusSign",$CLJS.rl,"+"],null)],null),$CLJS.G([d]))}();return $CLJS.R.h($CLJS.p,$CLJS.rg.h(function(d){var e=$CLJS.Qf(d);d=$CLJS.M.h(e,$CLJS.Xk);e=$CLJS.M.h(e,$CLJS.rl);switch(d){case "exponentSeparator":return"e";default:return e}},a))},kra=function(a,b){switch($CLJS.M4.g(b)){case "scientific":return function(c){return jra(a,c)};default:return function(c){return a.format(c)}}},
U4=function(a,b,c,d,e){this.options=a;this.rh=b;this.th=c;this.Ue=d;this.cj=e;this.C=393216;this.K=0},lra=function(a,b){return $CLJS.R.h($CLJS.p,function(){return function e(d){return new $CLJS.yf(null,function(){for(var f=d;;)if(f=$CLJS.A(f)){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Bf(l);return function(){for(var v=0;;)if(v<l){var x=$CLJS.be(g,v),y=$CLJS.Qf(x);x=$CLJS.M.h(y,$CLJS.Xk);y=$CLJS.M.h(y,$CLJS.rl);var B=new $CLJS.ni(null,new $CLJS.k(null,2,["currency",null,"integer",null],
null),null);B=B.g?B.g(x):B.call(null,x);$CLJS.m(B)&&(x=$CLJS.F.h(x,"integer")?b:y,n.add(x));v+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(n),e($CLJS.ad(f))):$CLJS.Ef($CLJS.Gf(n),null)}var q=$CLJS.C(f);q=$CLJS.Qf(q);var u=$CLJS.M.h(q,$CLJS.Xk);q=$CLJS.M.h(q,$CLJS.rl);if($CLJS.m(function(){var v=new $CLJS.ni(null,new $CLJS.k(null,2,["currency",null,"integer",null],null),null);return v.g?v.g(u):v.call(null,u)}()))return $CLJS.nf($CLJS.F.h(u,"integer")?b:q,e($CLJS.zd(f)));f=$CLJS.zd(f)}else return null},null,
null)}($CLJS.hF(a.rh.formatToParts(1),$CLJS.G([$CLJS.fk,!0])))}())},V4=function(a){var b=$CLJS.F.h($CLJS.M4.g(a),"currency")?2:null;b=new Intl.NumberFormat("en",$CLJS.kn($CLJS.aG($CLJS.Hb,$CLJS.Sh([$CLJS.gra,$CLJS.Zqa,$CLJS.bra,$CLJS.dra,$CLJS.ira,$CLJS.L4,$CLJS.cra,$CLJS.era,$CLJS.$qa,$CLJS.ara],[$CLJS.N4.h(a,b),$CLJS.Q4.g(a),$CLJS.P4.g(a),$CLJS.fra.g(a),$CLJS.F.h($CLJS.M4.g(a),"scientific")?null:$CLJS.M4.h(a,"decimal"),$CLJS.L4.g(a),!0,$CLJS.K4.h(a,b),$CLJS.F.h($CLJS.M4.g(a),"scientific")?"scientific":
null,$CLJS.R4.g(a)]))));var c=$CLJS.Q4.g(a);var d=$CLJS.L4.g(a);c=$CLJS.m(d)?null==c||$CLJS.F.h(c,"symbol"):d;d=kra(b,a);return new U4(a,b,c,d,$CLJS.P)},W4=function(a,b){return V4(S4(b)).Ff(null,a)},Y4=function(a,b){var c=$CLJS.Em.l(b,$CLJS.NG,$CLJS.G([$CLJS.M4])),d=Math.abs(a);if(0===a)return"0";if(1E3>d)return $CLJS.X4.h?$CLJS.X4.h(a,c):$CLJS.X4.call(null,a,c);b=$CLJS.C($CLJS.ug(function(f){return d>=$CLJS.C(f)},mra));var e=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return[$CLJS.p.g(function(){var f=
a/e,g=$CLJS.gn.l($CLJS.G([c,new $CLJS.k(null,2,[$CLJS.K4,1,$CLJS.N4,1],null)]));return $CLJS.X4.h?$CLJS.X4.h(f,g):$CLJS.X4.call(null,f,g)}()),$CLJS.p.g(b)].join("")},nra=function(a,b){var c=S4(b);if($CLJS.m(Z4.g(c)))b=Z4.g(c);else if($CLJS.Gb($CLJS.O4.g(c))&&$CLJS.Gb($CLJS.K4.g(c))&&$CLJS.ki.h($CLJS.M4.g(c),"currency")&&Math.abs(a)<($CLJS.F.h($CLJS.M4.g(c),"percent")?.01:1)){b=$CLJS.U.j;var d=$CLJS.Em.h(c,$CLJS.N4);c=$CLJS.R4.h(c,0);b=V4(b.call($CLJS.U,d,$CLJS.P4,2>c?2:c))}else b=V4(c);return T4(b,
a)};U4.prototype.O=function(a,b){return new U4(this.options,this.rh,this.th,this.Ue,b)};U4.prototype.N=function(){return this.cj};
U4.prototype.Ff=function(a,b){a=this.Ue.g?this.Ue.g(b):this.Ue.call(null,b);var c=$CLJS.hra.g(this.options);$CLJS.m($CLJS.m(c)?$CLJS.ki.h(c,".,"):c)&&(b=$CLJS.C(c),c=$CLJS.Zd(c),a=$CLJS.Nu(a,/[\.,]/,new $CLJS.k(null,2,[",",$CLJS.m(c)?c:"",".",b],null)));$CLJS.m(this.th)&&(b=$CLJS.L4.g(this.options),c=$CLJS.E4(b),a=$CLJS.Nu($CLJS.Nu(a,[$CLJS.ui(b)," "].join(""),c),$CLJS.ui(b),c));return a};
var Z4=new $CLJS.N(null,"number-formatter","number-formatter",929788393),$4=new $CLJS.N(null,"scale","scale",-230427353),a5=new $CLJS.N(null,"negative-in-parentheses","negative-in-parentheses",-1676084141),ora=new $CLJS.N(null,"scientific","scientific",316285837);var b5=new $CLJS.k(null,1,[$CLJS.Q4,"symbol"],null),pra=$CLJS.kn(b5),mra=new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[1E12,"T"],null),new $CLJS.S(null,2,5,$CLJS.T,[1E9,"B"],null),new $CLJS.S(null,2,5,$CLJS.T,[1E6,"M"],null),new $CLJS.S(null,2,5,$CLJS.T,[1E3,"k"],null)],null),c5=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.shared.formatting.numbers",
"format-number-compact*"),function(f,g){f=$CLJS.Qf(g);return $CLJS.M.h(f,$CLJS.M4)},$CLJS.Pj,e,a,b,c,d)}();c5.o(null,$CLJS.Pj,function(a,b){return Y4(a,b)});c5.o(null,"percent",function(a,b){return[$CLJS.p.g(Y4(100*a,b)),"%"].join("")});c5.o(null,"currency",function(a,b){b=$CLJS.gn.l($CLJS.G([b,b5]));var c=V4(b);return 1E3>Math.abs(a)?c.Ff(null,a):lra(c,Y4(a,b))});c5.o(null,"scientific",function(a,b){return W4(a,$CLJS.gn.l($CLJS.G([b,new $CLJS.k(null,2,[$CLJS.N4,1,$CLJS.K4,1],null)])))});
$CLJS.X4=function X4(a,b){b=$CLJS.pG(b);var d=$CLJS.Qf(b);b=$CLJS.M.h(d,$CLJS.NG);var e=$CLJS.M.h(d,a5),f=$CLJS.M.h(d,$CLJS.M4),g=$CLJS.M.h(d,$4);return $CLJS.m($CLJS.m(g)?!isNaN(g):g)?(b=g*a,f=$CLJS.Em.h(d,$4),X4.h?X4.h(b,f):X4.call(null,b,f)):$CLJS.m(function(){var l=0>a;return l?e:l}())?["(",$CLJS.p.g(function(){var l=-a,n=$CLJS.U.j(d,a5,!1);return X4.h?X4.h(l,n):X4.call(null,l,n)}()),")"].join(""):$CLJS.m(b)?c5.h(a,S4($CLJS.Em.h(d,$CLJS.NG))):$CLJS.F.h($CLJS.Oi.g(f),ora)?W4(a,d):nra(a,d)};
module.exports={compact_currency_options_js:pra,format_number:$CLJS.X4};